import React from 'react';

import { Layout, DemoButton } from '../components';

const GOOGLE_MAPS_API_KEY = 'AIzaSyCQ68hIu6Gt7CxhspUptWz3_CZQ0O4P23M';

const ContactUsPage = () => (
    <Layout title="Contact Us">
        <div className='full-page-container xl:relative flex flex-col xl:flex-none'>
            <div className='w-full h-1/2 xl:h-4/6'>
                <iframe
                    title="Integrum ESG headquarters map"
                    className='w-full h-full'
                    allowFullScreen
                    src={ `https://www.google.com/maps/embed/v1/place?q=place_id:ChIJPwk6dG8bdkgRH7fUiX_m0QU&zoom=15&key=${GOOGLE_MAPS_API_KEY}` }
                />
            </div>
            <div className='p-8 2xl:px-16 xl:py-12 xl:absolute xl:left-32 xl:bottom-32 bg-primary text-white border-b border-white xl:border-none'>
                <div className='text-4xl mb-8'>Integrum ESG</div>
                <div className='grid grid-cols-2 gap-4 2xl:gap-x-12 xl:gap-y-8 mb-8'>
                    <div className='row-span-2 flex flex-col justify-between text-lg font-light'>
                        <div>
                            20 Little Britain<br/>
                            London<br/>
                            EC1A 7DH<br/>
                            UK<br/>
                        </div>
                        <div>Company no. 11857070</div>
                    </div>
                    <div>
                        <div className='text-2xl mb-4'>Call</div>
                        <a className='text-lg font-light' href="tel:02034781144" aria-label="Call Integrum ESG">+44 20 3478 1144</a>
                    </div>
                    <div>
                        <div className='text-2xl mb-4'>Email</div>
                        <a className='text-lg font-light underline break-all' href="mailto:contact@integrumesg.com" aria-label="Email Integrum ESG">contact@integrumesg.com</a>
                    </div>
                </div>
                <div className='flex'>
                    <DemoButton/>
                </div>
            </div>
        </div>
        <div className='full-page-container xl:relative flex flex-col xl:flex-none'>
            <div className='w-full h-1/2 xl:h-4/6'>
                <iframe
                    title="Integrum ESG headquarters map"
                    className='w-full h-full'
                    allowFullScreen
                    src={ `https://www.google.com/maps/embed/v1/place?q=place_id:ChIJy2T0oxdawokRJqO3oh5aDl4&zoom=16&key=${GOOGLE_MAPS_API_KEY}` }
                />
            </div>
            <div className='p-8 2xl:px-16 xl:py-12 xl:absolute xl:left-32 xl:bottom-32 bg-primary text-white border-b border-white xl:border-none'>
                <div className='text-4xl mb-8'>Integrum ESG - NY</div>
                <div className='grid grid-cols-2 gap-4 2xl:gap-x-12 xl:gap-y-8 mb-8'>
                    <div className='row-span-2 flex flex-col justify-between text-lg font-light'>
                        <div>
                            28 Liberty Street<br/>
                            New York<br/>
                            10005<br/>
                            USA<br/>
                        </div>
                        <div>Company no. 11857070</div>
                    </div>
                    <div>
                        <div className='text-2xl mb-4'>Email</div>
                        <a className='text-lg font-light underline break-all' href="mailto:contact@integrumesg.com" aria-label="Email Integrum ESG">contact@integrumesg.com</a>
                    </div>
                </div>
                <div className='flex'>
                    <DemoButton/>
                </div>
            </div>
        </div>
    </Layout>
);

export default ContactUsPage;